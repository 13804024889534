import { createBrowserRouter } from "react-router-dom";

// Importo i layout
import Landing from "../layout/Landing";
import PageLogin from "../layout/PageLogin.js";
import Dashboard from "../layout/Dashboard.js";
// Error page
import ErrorPage from "../layout/error-page";
// dummy page
import Test from "../layout/Test.js";

import { routes as internalRoutes } from "./internal.js";

const root = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/login",
    element: <PageLogin />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: internalRoutes,
  },
  {
    path: "/project",
    element: <Dashboard />,
    children: internalRoutes,
  },

  {
    path: "/customers",
    element: <Dashboard />,
    children: internalRoutes,
  },
]);

export { root };
