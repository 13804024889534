import Header from "../components/pages/Header";
import WhyUs from "../components/pages/WhyUs";
import Services from "../components/pages/Services";
// import OurProject from "../components/pages/OurProject";
// import CustomerSay from "../components/pages/CustomerSay";
import ReadyToStart from "../components/pages/ReadyToStart";
import Footer from "../components/pages/Footer";
import GoToHome from "../components/GoToHome";
import GrowYourBusiness from "../components/pages/GrowYourBusiness";
// import Partners from "../components/pages/Partners";

export default function Landing() {
  return (
    <div className="App" id="home">
      <Header />
      <GrowYourBusiness />
      {/* <Partners /> */}
      <Services />
      <WhyUs />
      {/* <OurProject /> */}
      <ReadyToStart />
      {/* <CustomerSay /> */}
      <Footer />
      <GoToHome />
    </div>
  );
}
