// dummy page
import Test from "../layout/Test.js";


/** ICONS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faEnvelope,
  faPalette,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

/** PAGES */
import AddProject from "../components/AddProject";
import EditProject from "../components/EditProject";
import DummyEmail from "../components/pages/DummyEmail";
import CustomerSearch from "../components/pages/CustomersSearch.js";
import Dashboard from "../components/pages/Dashboard.js";

const routes = [
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "emailTest",
    element: <DummyEmail />,
  },
  {
    path: "search",
    element: <CustomerSearch />,
  },
  {
    path: "addProject",
    element: <AddProject />,
  },
  {
    path: "editProject",
    element: <EditProject />,
  },
];

const navRoutes = [
  {
    id: 0,
    link: "/dashboard",
    name: "Dashboard",
    icon: faHouse,
  },
  {
    id: 5,
    name: "Clienti",
    icon: faHandshake,
    link: "/customers/search",
    // children: [
    //   {
    //     id: 6,
    //     link: "/customers/search",
    //     name: "Ricerca",
    //   },
    // ],
  },
  {
    id: 1,
    link: "/dashboard/emailTest",
    name: "Email",
    icon: faEnvelope,
  },
  {
    id: 2,
    name: "Progetti",
    icon: faPalette,
    children: [
      {
        id: 3,
        link: "/project/addProject",
        name: "Aggiungi",
      },
      {
        id: 4,
        link: "/project/editProject",
        name: "Modifica",
      },
    ],
  },
];

export { routes, navRoutes };


