import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { ref, uploadBytes } from "firebase/storage";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { db, auth, storage } from "../config/firebase";
import { Auth } from "../components/auth";

export default function EditProject() {
  const [projectList, setProjectList] = useState([]);

  // UPDATE
  const [updatedName, setUpdateName] = useState("");

  // FILE UPLOAD
  const [fileUpload, setFileUpload] = useState(null);

  const projectCollectionRef = collection(db, "projects");

  const getProjectList = async () => {
    //Read the data
    try {
      const data = await getDocs(projectCollectionRef);
      // console.log("data", data);

      const filtredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      //SET the movie data
      setProjectList(filtredData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProjectList();
  }, []);

  // console.log(auth?.currentUser?.email);

  const deleteProject = async (id) => {
    const projectDoc = doc(db, "projects", id);
    await deleteDoc(projectDoc);
    getProjectList();
  };

  const updateProject = async (id) => {
    const projectDoc = doc(db, "projects", id);
    await updateDoc(projectDoc, { name: updatedName });
    getProjectList();
  };

  return (
    <div>
      {projectList.map((project) => (
        <div key={project.id}>
          <div className="h3">
            {project.name}{" "}
            <input
              placeholder="new title..."
              onChange={(e) => setUpdateName(e.target.value)}
            />{" "}
            <Button onClick={() => updateProject(project.id)}>Update</Button>
          </div>
          <div>{project.description}</div>
          <Button variant="danger" onClick={() => deleteProject(project.id)}>
            Elimina
          </Button>

          <div>
            <input
              type="file"
              onChange={(e) => setFileUpload(e.target.files[0])}
            />
            {/* <Button onClick={uploadFile}>Salva File</Button> */}
          </div>
        </div>
      ))}
    </div>
  );
}
