import { Container, Row, Col } from "react-bootstrap";

import Infographic from "../Infographic";
import { Title } from "../Texts";

import whyUs from "../../assets/img/whyUs.jpg";

const skills = [
  {
    id: 0,
    title: ["5+", "Anni di Esperienza"],
    description:
      "Con oltre 5 anni di esperienza nel settore, ci distinguiamo per la nostra profonda conoscenza e competenza acquisita nel corso degli anni. Abbiamo affrontato con successo una vasta gamma di progetti, affinando le nostre abilità e adattandoci alle mutevoli esigenze del mercato. La nostra lunga storia di successi testimonia il nostro impegno costante nel fornire soluzioni di alta qualità e soddisfare le aspettative dei nostri clienti.",
  },
  {
    id: 1,
    title: ["100", "Clienti Soddisfatti"],
    description:
      "Con oltre 100 clienti soddisfatti, dimostriamo il nostro impegno nel fornire soluzioni altamente personalizzate che superano le aspettative. Ogni cliente rappresenta una storia unica di successo, poiché ci sforziamo di comprendere appieno le loro esigenze e offrire soluzioni su misura che rispecchino perfettamente la loro visione. Grazie alla nostra attenzione ai dettagli e alla nostra capacità di adattamento, siamo riusciti a creare un legame duraturo con i nostri clienti, garantendo la loro piena soddisfazione e fiducia nel nostro lavoro.",
  },
  {
    id: 2,
    title: ["20", "Giorni per un sito completo"],
    description:
      "Per noi, l'impegno di realizzare un sito custom in soli 20 giorni è cruciale poiché rafforza il nostro impegno verso la tempestività e l'efficienza. Questo rapido turno di consegna ci consente di rispondere prontamente alle esigenze dei nostri clienti, offrendo soluzioni personalizzate senza compromettere la qualità. Inoltre, il coinvolgimento attivo del cliente in ogni fase del processo di sviluppo assicura che il risultato finale rispecchi esattamente le loro preferenze e aspettative, garantendo così la massima soddisfazione e fiducia nel nostro lavoro.",
  },
];

export default function WhyUs() {
  return (
    <Infographic className="text-center" altColor>
      <Container>
        <Row>
          <Col
            className="d-none d-md-block"
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            xs={12}
            md={6}
          >
            <div>
              <img src={whyUs} alt="Business" width="100%" />
            </div>
          </Col>

          <Col className="text-center">
            <Title className="text-white py-3">Perché noi?</Title>

            {skills.map((skill) => (
              <div key={skill.id}>
                <Row>
                  <Col md="auto">
                    <h1>{skill.title[0]}</h1>
                  </Col>
                  <Col className="text-start">
                    <h5>{skill.title[1]}</h5>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-start">
                    <p>{skill.description}</p>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Infographic>
  );
}
