// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAmanAiignhc5rv2uEL39EdAS6TteDPEHs",
  authDomain: "thinkforge-web.firebaseapp.com",
  projectId: "thinkforge-web",
  storageBucket: "thinkforge-web.appspot.com",
  messagingSenderId: "9698037381",
  appId: "1:9698037381:web:8dd85dc868c0745ff463ec",
  measurementId: "G-QKTSYKVQJ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const storage = getStorage(app);