import { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";

import AddProspect from "../prospect/AddProspect";
import ShowProspect from "../prospect/ShowProspect";
import Search from "../Search";

import { getProspect } from "../prospect/prospect";

export default function CustomerSearch() {
  const [prospects, setProspects] = useState([]);

  const handleGetProspect = async () => {
    const data = await getProspect();
    setProspects(data);
  };

  useEffect(() => {
    handleGetProspect();
  }, []);

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h3>Prospect</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Search />
        </Col>
        <Col className="text-end">
          <AddProspect handleGetProspect={handleGetProspect} />
        </Col>
      </Row>

      <Row>
        <Col>
          <ShowProspect className="mt-3" prospects={prospects} />
        </Col>
      </Row>
    </Container>
  );
}
