import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

export default function GoToHome() {
  return (
    <div
      style={{
        zIndex: "100",
        position: "fixed",
        right: "10px",
        bottom: "10px",
      }}
    >
      <HashLink smooth to="/home#top" style={{ color: "#fff" }}>
        <Button variant="light">
          <FontAwesomeIcon icon={faArrowUpLong} />
        </Button>
      </HashLink>
    </div>
  );
}
