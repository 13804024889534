// FIREBASE
import { addDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../config/firebase";

// FIRESTORE
import { auth } from "../../config/firebase";

const prospectCollectionRef = collection(db, "prospects");

const getProspect = async () => {
  //Read the data
  try {
    const data = await getDocs(prospectCollectionRef);
    const filtredData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    //SET the prospect data
    return filtredData;
  } catch (error) {
    console.error(error);
  }
};

const addProspect = async ({ name, surname, email }) => {
  console.log(auth);

  try {
    await addDoc(prospectCollectionRef, {
      name,
      surname,
      email,
      userId: auth?.currentUser?.uid ? auth?.currentUser?.uid : "external",
    });

    return { state: "success" };
  } catch (err) {
    const error = { state: "error", message: err };
    console.error(error);
    return error;
  }
};

export { getProspect, addProspect };
