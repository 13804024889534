import { Button, Container, Row, Col } from "react-bootstrap";
import Infographic from "../Infographic";

import { Title, Subtitle } from "../Texts";

import main from "../../assets/img/main.jpg";
import { HashLink } from "react-router-hash-link";

export default function GrowYourBusiness() {
  return (
    <Infographic id="GrowYourBusiness">
      <Container>
        <Row className="my-5 py-5">
          <Col xs={12} md={6} className="text-center text-md-start">
            <Title>
              Fai crescere il tuo <br />
              Business <br />
              con noi
            </Title>
            <Subtitle>
              Espandi le tue possibilità con i nostri servizi web
              personalizzati. Dalla progettazione di siti all'ottimizzazione
              SEO, noi ti copriamo. Affidati a noi per far crescere il tuo
              business online con successo, creando webpage personalizzate che
              si distinguono per design unico e funzionalità avanzate.
            </Subtitle>
            <HashLink smooth to="/home#contattaci">
              <Button variant="dark">Scopri Come</Button>
            </HashLink>
          </Col>
          <Col className="text-center ">
            <img src={main} alt="Business" width="100%" />
          </Col>
        </Row>
      </Container>
    </Infographic>
  );
}
