import { Children } from "react";
import { Modal } from "react-bootstrap";
// import Button from "react-bootstrap/Button";

export default function CustomModal({ show, setShow, children, params }) {
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={params.keyboard ? params.keyboard : false}
        centered={params.center ? params.center : false}
      >
        {children}
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
