import { useEffect, useState } from "react";
import { Modal as ReactModal } from "react-bootstrap";

import Button from "./Button";

export default function Modal({
  children,
  handleSubmitData,
  handleGetProspect,
  buttonName,
}) {
  const [show, setShow] = useState(false);
  const [reqState, setReqState] = useState();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (reqState === "success") {
      // Se va a buon fine, chiudi il modal.
      setShow(false);
      handleGetProspect();
    }

    if (reqState === "error") {
      // TODO: fai qualcosa per gestire gli errori quando la chiamata va in errore.
    }
  }, [reqState]);

  return (
    <>
      <Button onClick={handleShow}>{buttonName}</Button>
      <ReactModal show={show} onHide={handleClose}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>{buttonName}</ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body>{children}</ReactModal.Body>

        <ReactModal.Footer>
          <Button onClick={handleClose} variant="danger">
            Chiudi
          </Button>
          <Button async setReqState={setReqState} onClick={handleSubmitData}>
            Aggiungi
          </Button>
        </ReactModal.Footer>
      </ReactModal>
    </>
  );
}
