import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeadset,
  faBullseye,
  faHammer,
  // faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";

import Box from "../Box";
import Infographic from "../Infographic";
import { Title, Subtitle } from "../Texts";




export default function Services() {
  const styleCenterCol = {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  };

  const services = [
    {
      id: 0,
      title: "Assistenza IT e Supporto Tecnico",
      boxStyle: {
        backgroundColor: "#212529",
        color: "#fff",
      },
      style: { color: "#fff" },
      description:
        "Forniamo assistenza remota per risolvere problemi tecnici, installare e configurare software, e fornire supporto tecnico su vari dispositivi e piattaforme.",
      icon: faHeadset,
    },
    {
      id: 1,
      title: "Digital Marketing",
      boxStyle: {
        backgroundColor: "#212529",
        color: "#fff",
      },
      style: { color: "#fff" },
      description:
        "Consulenza su strategie di marketing digitale, inclusa la pubblicità online, l'ottimizzazione dei motori di ricerca (SEO), la gestione dei social media e altro ancora.",
      icon: faBullseye,
    },
    {
      id: 2,
      title: "Progettazione Software Custom",
      boxStyle: {
        backgroundColor: "#212529",
        color: "#fff",
      },
      style: { color: "#fff" },
      description:
        "Approfondiamo la tua visione, analizziamo i requisiti e creiamo soluzioni software innovative e altamente personalizzate, progettate per aumentare l'efficienza, migliorare i processi aziendali e superare le sfide quotidiane. ",
      icon: faHammer,
    },
  ];

  return (
    <Infographic className="text-center" id="servizi">
      <Title>
        Risparmia tempo e concentrati <br />
        sul tuo Business
      </Title>
      <Subtitle>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu
        lectus id orci fringilla rhoncus id varius ante. Morbi nec tempor dolor.
        Mauris nunc sem, molestie et pellentesque sit amet, efficitur sed nisi.
      </Subtitle>
      <Container className="mt-5">
        <Row>
          {services.map((service) => (
            <Col
              style={styleCenterCol}
              xs={12}
              md={4}
              key={service.id}
              className="my-2 my-md-0"
            >
              <div
                // className="bg-primary"
                style={{
                  ...service.boxStyle,
                  borderRadius: "30px",
                  padding: "15px",
                }}
              >
                <FontAwesomeIcon
                  icon={service.icon}
                  size="2xl"
                  style={service.style}
                />
                <h5 className="my-3" style={service.style}>
                  {service.title}
                </h5>
                <p style={{ fontSize: "12px" }}>{service.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Infographic>
  );
}
