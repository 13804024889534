import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Auth } from "../components/auth";
import { auth } from "../config/firebase";

export default function PageLogin() {
  // Larghezza massima del form login
  const MAX_WIDTH = "400px";

  const navigate = useNavigate();

  // Reindirizzo se sono loggato
  useEffect(() => {
    if (auth?.currentUser) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          className="text-center"
          style={{
            width: MAX_WIDTH,
          }}
        >
          <h2>ThinkForge</h2>
          <Auth />
        </div>
      </div>
    </>
  );
}
