import { useState } from "react";

import { Button as ButtonReact } from "react-bootstrap";

import "./ButtonStyle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const BTN_TEMP_ATTESA = (
  <>
    <FontAwesomeIcon icon={faCircleNotch} className="me-1" spin />
    Attendi
  </>
);

const BTN_SUCCESS = (
  <>
    <FontAwesomeIcon icon={faCheck} className="me-1" />
    Salvato
  </>
);

const BTN_ERROR = (
  <>
    <FontAwesomeIcon icon={faXmark} className="me-1" />
    Errore
  </>
);

export default function Button({
  children,
  onClick,
  className,
  variant = "dark",
  async = false,
  setReqState, // è lo stato del bottone quando esegue richieste async
}) {
  const [disable, setDisable] = useState(false);

  const [buttonState, setButtonState] = useState(false);

  // Gestici la pressione del tasto
  const handleClick = async () => {
    // disabilita la pressione & avvisa l'utente
    setDisable(true);

    // Esegui l'azione
    const { state } = await onClick();
    setReqState(state);

    // console.log("async state", state);

    if (state === "success") {
      // Reimpostalo come cliccabile
      setDisable(false);
      setButtonState("success");
    } else if (state === "error") {
      // return erro
      setDisable(false);
      setButtonState("error");
    } else {
      // if I die
    }
  };

  return (
    <>
      {!async ? (
        <ButtonReact onClick={onClick} className={className} variant={variant}>
          {children}
        </ButtonReact>
      ) : (
        /* Async Button */
        <ButtonReact
          onClick={handleClick}
          className={className}
          variant={variant}
          disabled={disable}
        >
          {!disable && !buttonState ? children : ""}
          {disable && !buttonState ? BTN_TEMP_ATTESA : ""}
          {buttonState === "success" ? BTN_SUCCESS : ""}
          {buttonState === "error" ? BTN_ERROR : ""}
        </ButtonReact>
      )}
    </>
  );
}
