// React
import { useState } from "react";
// Grafica
import { Button, Form, Modal } from "react-bootstrap";

// MODAL
import CustomModal from "./CustomModal";

// Icone
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

// Firebase
import { auth, googleProvider } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

// ROUTER
import { useNavigate } from "react-router-dom";

// REGEX EMAIL
const EMAIL_REGEX_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PASS_REGEX_PATTERN =
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{12,}$/i;

export const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // Handling Errors

  // MODAL
  const [formError, setFormErrors] = useState(undefined);
  const [show, setShow] = useState(false);
  const ModalParams = {
    center: true,
    keyboard: false,
  };

  // Router
  const navigate = useNavigate();

  // console.log(auth.currentUser?.email);

  const validate = () => {
    setFormErrors(undefined);

    if (
      email.match(EMAIL_REGEX_PATTERN) &&
      password.match(PASS_REGEX_PATTERN)
    ) {
      return;
    }

    if (email === "") {
      setFormErrors(<p>la username non può essere vuota</p>);
      setShow(true);
      return;
    }

    if (!email.match(EMAIL_REGEX_PATTERN)) {
      setFormErrors(<p>la username deve essere un'email</p>);
      setShow(true);
      return;
    }

    if (password === "") {
      setFormErrors(<p>la password non può essere vuota</p>);
      setShow(true);
      return;
    }

    if (!password.match(PASS_REGEX_PATTERN)) {
      setFormErrors(
        <div>
          la password non è forte deve avere: <br />
          almeno 1 maiuscola,
          <br />
          almeno 1 carattere speciale,
          <br />
          almeno una lunghezza di 12 caratteri
        </div>
      );
      setShow(true);
      return;
    }

    setFormErrors(<p>Generic Error</p>);
    setShow(true);
  };

  // Registrati con Email
  const signIn = async () => {
    try {
      // Verifica i dati inseriti
      validate();
      if (formError === undefined) {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate("/dashboard");
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Registrati con Email
  const logIn = async () => {
    try {
      // Verifica i dati inseriti
      validate();
      if (formError === undefined) {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/dashboard");
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Registrati con Google
  const signWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="text-center mt-5">
        <div>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
              className="my-3"
            />
          </Form.Group>
        </div>
        <div className="col text-end">
          <Button onClick={signWithGoogle} variant="dark" className="ms-2">
            Accedi con <FontAwesomeIcon icon={faGoogle} />
          </Button>
          <Button onClick={logIn} variant="dark" className="ms-2">
            Accedi
          </Button>
          {/* <Button onClick={signout}>Esci</Button> */}
        </div>
      </div>
      <CustomModal show={show} setShow={setShow} params={ModalParams}>
        <Modal.Header closeButton>
          <Modal.Title>Accesso non valido</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{formError}</p>
        </Modal.Body>
      </CustomModal>
    </>
  );
};
