import { useEffect, useState } from "react";

// BOOTSTRAP
import { Col, Container, Row } from "react-bootstrap";

// ROUTER
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

/** ICONS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalkingArrowRight } from "@fortawesome/free-solid-svg-icons";

// Firebase
// import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

// COMPONENTS
import LeftNav from "../components/LeftNav";

const BG_COLOR = "#F6F8FF";

export default function Dashboard() {
  // Router
  const navigate = useNavigate();

  // // SIGNOUT
  // const signout = async () => {
  //   try {
  //     await signOut(auth);
  //     navigate("/login");
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // Reindirizzo se non sono loggato
  useEffect(() => {
    if (!auth?.currentUser) {
      navigate("/login");
    }
  }, []);

  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
      }}
    >
      <LeftNav />

      {/* MAIN SECTION */}
      <div
        className="ps-0 pt-0"
        style={{ width: "100%", backgroundColor: BG_COLOR }}
      >
        {/* TOP MENU */}
        {/* <div style={{ width: "100%" }} className="m-0 mb-3 py-2">
          <Container>
            <Row>
              <Col className="pe-3"></Col>
            </Row>
          </Container>
        </div> */}

        {/* MAIN CONTENT */}
        <main className="ps-3">
          <Container>
            <Row>
              <Col>
                <Outlet />
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}
