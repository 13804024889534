// Grafica
import { Col, Form, Row } from "react-bootstrap";
// import Button from "../components/Button";
import Button from "../Button";

// ROUTER
// import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function DummyEmail() {
  // EMAIL
  const [emailTo, setEmailTo] = useState("");
  const [emailFrom, setEmailFrom] = useState("info@thinkforge.it");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailText, setEmailText] = useState("");

  const [emailMessage, setEmailMessage] = useState("");

  const sendEmail = () => {
    fetch("/api/v1/sendEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: emailTo,
        from: emailFrom,
        subject: emailSubject,
        text: emailText,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setEmailMessage(data.message);
      });
  };

  const sendTestEmail = () => {
    fetch("/api/sendTestEmail")
      .then((res) => res.json())
      .then((data) => console.log(data.message));
  };

  return (
    <div className="my-3 mx-5">
      <h3>Invio di email</h3>
      <div className="mb-5">
        <Form.Group as={Row} className="mt-1">
          <Form.Label column sm={2}>
            Da
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="Da"
              value={emailFrom}
              onChange={(e) => setEmailFrom(e.target.value.trim())}
              className="disabled"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3">
          <Form.Label column sm={2}>
            A
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="..."
              value={emailTo}
              onChange={(e) => setEmailTo(e.target.value.trim())}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3">
          <Form.Label column sm={2}>
            Oggetto
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              placeholder="..."
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3">
          <Form.Label column sm={2}>
            Messaggio
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="..."
              value={emailText}
              onChange={(e) => setEmailText(e.target.value)}
            />
          </Col>
        </Form.Group>
        <div className="mt-5 text-end">
          <Button onClick={sendTestEmail} variant="pericolo">
            EmailTest
          </Button>
          <Button className="ms-3" onClick={sendEmail} variant="primario">
            EmailTest
          </Button>
        </div>
        <div>{emailMessage}</div>
      </div>
    </div>
  );
}
