import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Infographic from "../Infographic";
import { Subtitle, Title } from "../Texts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import CustomModal from "../CustomModal";
import { Modal } from "react-bootstrap";

import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";



// Salva il cliente anche sul db: 
import {addProspect} from '../prospect/prospect'

const EMAIL_REGEX_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export default function ReadyToStart() {
  const [completed, setCompleted] = useState(false);
  const [callToAction, setCallToAction] = useState(false);

  // MODAL
  const [formError, setFormErrors] = useState("");
  const [show, setShow] = useState(false);
  const ModalParams = {
    center: true,
    keyboard: false,
  };

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");

  // controllo se i dati inserito sono sufficienti
  function formValidation() {
    if (name !== "" && surname !== "" && email.match(EMAIL_REGEX_PATTERN)) {
      return true;
    } else {
      return false;
    }
  }

  // Aggiundo a db il prospect
  const handleSubmitData = async () => {
    const newProspect = {
      name,
      surname,
      email,
    };

    try {
      await addProspect(newProspect);
      return { state: "success" };
    } catch (error) {
      return { state: "error", message: error };
    }
  };

  // Passami le informazioni
  function storeProspect() {
    // Salvo a db il prospect:
    handleSubmitData();
    // TODO: salvati i dati del prospect
    fetch("/api/v1/sendEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        to: "filippofrigo@outlook.it",
        from: "info@thinkforge.it",
        subject: "Prospect da ThinkForge.it",
        text: `Ciao, hai ricevuto una richiesta da ${name} ${surname}, dall'email ${email}. Contattalo`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.message);
      });

    // TODO: dai conferma dell'avvenuto invio
    setCompleted(true);

    // TODO: dai maggiori info e congratulati...
  }

  function handleErrors() {
    //  spiega che qualcosa non va bene
    setFormErrors(
      "Sembra che ci sia stato un problema. Per favore, controlla i dati inseriti e riprova. Se il problema persiste, contattaci per assistenza."
    );

    // TODO: Aiuta il prospect a completare la richiesta, indicando alcuni possibili problemi di invio del form
    setShow(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    formValidation() ? storeProspect() : handleErrors();
  };

  // Gestisco L'abilitazione del pulsante Call to Action!
  useEffect(() => {
    formValidation() ? setCallToAction(true) : setCallToAction(false);

    // TODO : aggiungo  della grafica accattivante quando il pulsante si attiva!
  }, [name, surname, email]);

  return (
    <Infographic /*altColor*/ id="contattaci">
      {!completed ? (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col>
                <Title /*altColor*/>
                  Prondo a iniziare
                  <br /> il tuo progetto?
                </Title>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Control
                    type="text"
                    placeholder="Cognome"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                {/* </Form> */}
              </Col>
              <Col xs={12} md={6} className="mt-5 mt-md-0">
                <Subtitle>
                  Lascia che il nostro team ti aiuti a realizzare il tuo
                  progetto. Con la nostra competenza e la nostra passione, siamo
                  pronti a collaborare con te per dare vita alla tua idea. Non
                  vediamo l'ora di lavorare insieme per raggiungere il successo.
                </Subtitle>
                <div className="text-center pt-3">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    variant="light"
                    className={callToAction ? "" : "disabled"}
                  >
                    {callToAction
                      ? "Iniziamo! "
                      : "Completa il form per iniziare!"}
                    <FontAwesomeIcon
                      icon={faFire}
                      className={callToAction ? "" : "d-none"}
                      // size="2xl"
                      style={{ color: "red" }}
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      ) : (
        <Container>
          <Row>
            <Col>
              <Title
                altColor
                className="text-center
              "
              >
                Congratulazioni!
              </Title>
            </Col>
          </Row>
          <Row>
            <Col className="text-center col-12 col-md-auto m-3 m-md-0">
              <FontAwesomeIcon
                icon={faThumbsUp}
                size="6x"
                style={{
                  color: "#fff",
                  "--fa-animation-delay": "1s",
                  "--fa-animation-duration": "2s",
                  "--fa-animation-iteration-count": "2",
                }}
                shake
              />
            </Col>
            <Col className="mx-3 my-2">
              <Subtitle style={{ color: "#fff" }}>
                Grazie {name} per aver scelto di unirti a noi! Siamo entusiasti
                di aver ricevuto le tue informazioni. La tua registrazione è
                stata confermata con successo. Presto riceverai un'email di
                recap, e un referente ti contatterà per scoprire il progetto che
                vuoi realizzare. Resta connesso per ricevere aggiornamenti e
                contenuti esclusivi!
              </Subtitle>
            </Col>
          </Row>
        </Container>
      )}
      <CustomModal show={show} setShow={setShow} params={ModalParams}>
        <Modal.Header closeButton>
          <Modal.Title>Ci sei quasi!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{formError}</p>
        </Modal.Body>
      </CustomModal>
    </Infographic>
  );
}
