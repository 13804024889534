import Badge from "react-bootstrap/Badge";

export default function Logo({ size = "normal", onClick }) {
  // const

  var LetterSpacing;
  var Size;
  var FontSize;
  var Margin;
  var content;

  switch (size) {
    case "small":
      LetterSpacing = "2px";
      Size = "40px";
      FontSize = "11pt";
      Margin = "0 0 0 0";
      content = "TF";
      break;

    case "normal":
    default:
      LetterSpacing = "6px";
      FontSize = "22pt";
      Size = "150px";
      Margin = "15px 0 0 0";
      content = (
        <>
          Think
          <br />
          Forge
        </>
      );

      break;
  }

  return (
    <Badge
      bg="black"
      className="font-weight-bold"
      style={{
        letterSpacing: LetterSpacing,
        width: Size,
        fontSize: FontSize,
        margin: Margin,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div>{content}</div>
    </Badge>
  );
}
