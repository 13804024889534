// REACT CORE
import React from "react";
import ReactDOM from "react-dom/client";

// Bootstrap Styles
import "bootstrap/dist/css/bootstrap.min.css";
// FONT GOOGLE
import "./assets/css/fonts.css";
import "./assets/css/index.css";

// ROTTE
import { root as rootRouter } from "./routes/root.js";

// ROUTER
import { RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={rootRouter} />
  </React.StrictMode>
);
