import { Card, Col, Container, Row } from "react-bootstrap";

import { HOSTNAME, requestAPI } from "../../config/node";
import { useEffect, useState } from "react";

export default function Dashboard() {
  const [version, setVersion] = useState("");

  useEffect(() => {
    const requestVersion = async () => {
      const { message, version } = await requestAPI("/echo");

      setVersion(version);
    };
    requestVersion();
  }, []);

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h3>Dashboard</h3>
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <Card bg="dark" text="white">
            <Card.Header>Featured</Card.Header>
            <Card.Body>
              <Card.Title>Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">2 days ago</Card.Footer>
          </Card>
        </Col> */}

        <Col>
          <Card body>placeholder</Card>
        </Col>

        <Col>
          <Card>
            <Card.Header>version {version}</Card.Header>
            <Card.Body>
              <Card.Title>Backend</Card.Title>
              <Card.Text>
                Al momento non ci sono maggiori informazioni.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-muted">now</Card.Footer>
          </Card>
        </Col>

        <Col>
          <Card body>placeholder</Card>
        </Col>
      </Row>
    </Container>
  );
}
