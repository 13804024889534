import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faCopyright,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { Link } from "react-router-dom";

import CustomModal from "../CustomModal";
import { Modal } from "react-bootstrap";

const EMAIL_REGEX_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export default function Footer() {
  // FORM
  const [newsletter, setNewsletter] = useState("");
  const [subscription, setSubscription] = useState(false);

  // MODAL
  const [formError, setFormErrors] = useState("");
  const [show, setShow] = useState(false);
  const ModalParams = {
    center: true,
    // keyboard: false,
  };

  const handleSubscription = (event) => {
    setSubscription(!subscription);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!subscription) {
      setFormErrors(
        'È necessario selezionare l\'opzione "attiva sottoscrizione alla newsletter" per ricevere i nostri consiglio via email. Assicurati di spuntare la casella corrispondente e riprova'
      );
      setShow(true);
      return;
    }

    // FIXME: l'utente può iscriversi con la stessa email più volte.
    if (newsletter.match(EMAIL_REGEX_PATTERN)) {
      setFormErrors(
        `Congratulazioni! La tua iscrizione alla nostra newsletter è avvenuta con successo. Presto riceverai le ultime novità, offerte speciali e aggiornamenti direttamente nella tua casella di posta ${newsletter}. Grazie per aver scelto di rimanere aggiornato/a con noi!`
      );
      setShow(true);

      // TODO: iscrivi veramente l'utente ad una newsletter
    } else {
      // alert("email non valida");
      setFormErrors(
        "E-mail non valida. Assicurati di inserire un indirizzo e-mail corretto (ad esempio: example@example.com) e riprova"
      );
      setShow(true);
    }
  };

  return (
    <>
      <div id="footer" className="bg-dark">
        <Container>
          <Row className="py-5 text-light">
            <Col xs={12} md={4}>
              <h4>ThinkForge</h4>
              <p>
                Benvenuto! Qui troverai idee e suggerimenti che potranno
                aiutarti nella realizzazione di spettacolari siti web.
              </p>

              <p>
                ThinkForge 2024 <FontAwesomeIcon icon={faCopyright} />. Tutti i
                diritti sono riservati.
              </p>

              <p>
                Creato con amore dal nostro Team{" "}
                <FontAwesomeIcon icon={faHeart} className="text-danger" />
              </p>
            </Col>

            <Col xs={12} md={4} className="mt-5 mt-md-0"></Col>

            {/* SEZIONE ISCRIZIONE */}
            <Col xs={12} md={4} className="mt-5 mt-md-0">
              <h4>Iscrizione</h4>
              <Form onSubmit={handleSubmit}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="la tua email"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setNewsletter(e.target.value)}
                  />

                  <Button variant="outline-secondary" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </InputGroup>

                <Form.Check
                  type="checkbox"
                  label="attiva sottoscrizione alla newsletter"
                  className="pt-2"
                  checked={subscription}
                  onChange={handleSubscription}
                />
              </Form>
            </Col>
          </Row>
          <Row className="text-light p-0 pb-2">
            <Col xs={12} md={12}>
              <Link to="/dashboard" className="text-decoration-none text-light">
                Area Riservata
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomModal show={show} setShow={setShow} params={ModalParams}>
        <Modal.Header closeButton>
          <Modal.Title>Iscrizione alla newsletter</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{formError}</p>
        </Modal.Body>
      </CustomModal>
    </>
  );
}
