// Questo è il collegamento con il backend

const NODE_PORT = 3001;
const BASE_URL = `/api/v1`;

const API_KEY =
  "r5VDisUF6rX7nUr9RiDGkBRjeqEd8zndBwmZu6FPCzwbdh3zMuHm5j7DZjP4hxc9r3NrQqcEP3XnKhV46R2XTTFckzUXWqmLenzp";

// Ritorna localhost:3003
const HOST = window.location.host;
// Ritorna localhost
const HOSTNAME = window.location.hostname;
export { HOST, HOSTNAME };

const requestAPI = async (request, method = "GET", json = {}) => {
  switch (method) {
    case `POST`:
      return await fetch(BASE_URL + request, {
        method: method,
        headers: { "Content-Type": "application/json", "x-api-key": API_KEY },
        body: JSON.stringify(json),
      })
        .then((res) => res.json())
        .then((data) => {
          //   console.log("data", data);
          return data;
        });

    case `GET`:
      return await fetch(BASE_URL + request, {
        method: method,
        headers: { "Content-Type": "application/json", "x-api-key": API_KEY },
      })
        .then((res) => res.json())
        .then((data) => {
          //   console.log("data", data);

          return data;
        });

    default:
      console.error(`Method ${method} not found`);
      break;
  }
};

export { requestAPI };
