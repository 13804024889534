import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Col } from "react-bootstrap";

export default function Input({ placeholder, onChange, label, className }) {
  return (
    <InputGroup className={className}>
      {label ? (
        <Form.Label column sm="2">
          {label}
        </Form.Label>
      ) : (
        ""
      )}
      <Col sm={label ? 10 : ""}>
        <Form.Control placeholder={placeholder} onChange={onChange} />
      </Col>
    </InputGroup>
  );
}
