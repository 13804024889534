import { useState } from "react";

// FIRESTORE
// import { auth } from "../../config/firebase";

import Modal from "../Modal";
import Input from "../Input";

import { addProspect as addProspectToDb } from "./prospect";

export default function AddProspect({ handleGetProspect }) {
  // ADD DATA
  const [prospectName, setProspectName] = useState("");
  const [prospectSurname, setProspectSurname] = useState("");
  const [prospectEmail, setProspectEmail] = useState("");

  // Aggiundo a db i dati
  const handleSubmitData = async () => {
    const newProspect = {
      name: prospectName,
      surname: prospectSurname,
      email: prospectEmail,
    };

    try {
      await addProspectToDb(newProspect);
      return { state: "success" };
    } catch (error) {
      return { state: "error", message: error };
    }
  };

  return (
    <>
      <Modal
        handleSubmitData={handleSubmitData}
        handleGetProspect={handleGetProspect}
        buttonName="Inserisci Prospect"
      >
        <Input label="Nome" onChange={(e) => setProspectName(e.target.value)} />
        <Input
          label="Cognome"
          onChange={(e) => setProspectSurname(e.target.value)}
          className="my-2"
        />
        <Input
          label="Email"
          onChange={(e) => setProspectEmail(e.target.value)}
        />
      </Modal>
    </>
  );
}
