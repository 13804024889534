import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { Link } from "react-router-dom";

export default function CollapsableElement({
  name,
  hideText,
  links,
  icon,
  size,
  className,
}) {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div
      onClick={handleToggle}
      style={{ cursor: "pointer" }}
      onMouseLeave={() => setToggle(false)}
    >
      <FontAwesomeIcon
        // icon={toggle ? faChevronDown : faChevronRight}
        icon={icon}
        size={size}
        className={className}
        fixedWidth
      />
      {!hideText ? (
        <>
          <b>{name}</b>{" "}
          <FontAwesomeIcon
            icon={toggle ? faChevronDown : faChevronRight}
            // icon={icon}
            size="sm"
            className={className}
            fixedWidth
          />
        </>
      ) : (
        ""
      )}

      {toggle ? (
        <ul className="list-unstyled ps-4">
          {links.map((link) => (
            <li id={link.id}>
              <Link to={link.link} className="text-decoration-none link-dark">
                {!hideText ? link.name : ""}
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}
