import { Form, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function Search({ onChange, placeholder = "Ricerca..." }) {
  return (
    <InputGroup>
      <InputGroup.Text>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </InputGroup.Text>
      <Form.Control placeholder={placeholder} onChange={onChange} />
    </InputGroup>
  );
}
