import { Table } from "react-bootstrap";
export default function ShowProspect({ className, prospects }) {
  // console.log("prospect", prospects);

  return (
    <Table striped bordered hover size="sm" className={className}>
      <thead>
        <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        {prospects.map((prospect) => (
          <tr id={prospect?.id}>
            <td>{prospect?.id}</td>
            <td>{prospect?.name}</td>
            <td>{prospect?.surname}</td>
            <td>{prospect?.email}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
