/**
 * Questo componente è il menu sinistro all'interno dell'area utente.
 *
 */

import { useState } from "react";

// BOOTSTRAP
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import userImg from "../assets/img/placeholder/user.png";
import Badge from "react-bootstrap/Badge";

// ROUTER
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import { navRoutes as routes } from "../routes/internal";

import CollapsableElement from "./CollapsableElement";
// import CollapsableNav from "./CollapsableNav";

import {
  faGear,
  faPersonWalkingArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Firebase
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

// COMPONENTS
import Logo from "./Logo";

export default function LeftNav() {
  const [hamburger, setHamburger] = useState(true);

  const [hover, setHover] = useState(null);

  const hamburgerWidth = hamburger ? "80px" : "250px";

  const handleHamburger = () => {
    setHamburger(!hamburger);
  };

  const handleHover = (id) => {
    setHover(id);
  };

  const navigate = useNavigate();

  // SIGNOUT
  const signout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const [Exit, setExit] = useState(false);

  return (
    <div
      className="px-0 mx-0 py-3 ps-3"
      style={{
        width: hamburgerWidth,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // borderRight: "1px solid #000",
        // boxShadow: "10px 0px 5px #dedede",
      }}
      onMouseLeave={() => setHamburger(true)}
      onMouseEnter={() => setHamburger(false)}
    >
      <Logo size="small" onClick={handleHamburger} />
      {/* <CollapsableNav onClick={handleHamburger} /> */}
      {/* <hr /> */}
      <div
        className="m-0 p-0"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          // alignItems: "center",
        }}
      >
        <div className="py-3">Menu</div>
        <ul className="list-unstyled">
          {routes.map((nav) => (
            <li
              className="d-flex px-2"
              id={nav.id}
              onMouseEnter={() => handleHover(nav.id)}
              onMouseLeave={() => handleHover(null)}
              style={{
                cursor: "pointer",
                borderRadius: "20px",
                backgroundColor: `${hover === nav.id ? "#F6F8FF" : ""}`,
              }}
            >
              {nav.children ? (
                <CollapsableElement
                  size="xl"
                  name={nav.name}
                  hideText={hamburger}
                  icon={nav.icon}
                  links={nav.children}
                  className="pe-1 my-1"
                />
              ) : (
                <Link to={nav.link} className="text-decoration-none link-dark">
                  <FontAwesomeIcon
                    icon={nav.icon}
                    size="xl"
                    fixedWidth
                    className="my-1"
                  />{" "}
                  {!hamburger ? <b>{nav.name}</b> : ""}
                </Link>
              )}
            </li>
          ))}
          {hamburger ? (
            <hr width="40px" className="ms-2" />
          ) : (
            <hr width="150px" />
          )}

          <li
            className="d-flex px-2"
            id={99}
            onMouseEnter={() => handleHover(99)}
            onMouseLeave={() => handleHover(null)}
            style={{
              cursor: "pointer",
              borderRadius: "20px",
              backgroundColor: `${hover === 99 ? "#F6F8FF" : ""}`,
            }}
          >
            <Link className="text-decoration-none link-dark">
              <FontAwesomeIcon
                icon={faGear}
                size="xl"
                className="my-1"
                fixedWidth
              />{" "}
              {!hamburger ? <b>Impostazioni</b> : ""}
            </Link>
          </li>
        </ul>
      </div>
      <div className="" style={{ cursor: "pointer" }}>
        <Row className="p-0 m-0">
          <Col
            className="p-0 m-0 me-2 pt-1"
            onMouseEnter={() => setExit(true)}
            onMouseLeave={() => setExit(false)}
          >
            {!Exit ? (
              <Image src={userImg} roundedCircle style={{ width: "40px" }} />
            ) : (
              <div
                className="rounded-circle"
                style={{ backgroundColor: "#F6F8FF" }}
              >
                <FontAwesomeIcon
                  icon={faPersonWalkingArrowRight}
                  size="xl"
                  className="p-2"
                  flip="horizontal"
                  onClick={signout}
                />
              </div>
            )}
          </Col>
          {!hamburger ? (
            <Col className="p-0 m-0">
              <div
                className="p-0 m-0"
                style={{ fontSize: "12pt", fontWeight: "bold" }}
              >
                user
              </div>
              <div className="p-0 m-0" style={{ fontSize: "10pt" }}>
                {auth?.currentUser?.email}
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    </div>
  );
}
