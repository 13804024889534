import { useState } from "react";

import { Button } from "react-bootstrap";

import { addDoc, collection } from "firebase/firestore";

import { auth, db } from "../config/firebase";

export default function AddProject() {
  // ADD DATA
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectCall, setProjectCall] = useState("");
  const [projectImg, setProjectImg] = useState("");
  const [projectAlternate, setProjectAlternate] = useState(false);

  const projectCollectionRef = collection(db, "projects");

  const handleSubmitData = async () => {
    try {
      await addDoc(projectCollectionRef, {
        name: projectName,
        description: projectDescription,
        call: projectCall,
        img: projectImg,
        alternate: projectAlternate,
        userId: auth?.currentUser?.uid,
      });
      //   getProjectList();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <h2>Add new Project</h2>
      <input
        placeholder="Project Name..."
        onChange={(e) => setProjectName(e.target.value)}
      />
      <br />
      <input
        placeholder="Description..."
        onChange={(e) => setProjectDescription(e.target.value)}
      />
      <br />
      <input
        placeholder="Img..."
        onChange={(e) => setProjectImg(e.target.value)}
      />
      <br />
      <input
        placeholder="Call..."
        onChange={(e) => setProjectCall(e.target.value)}
      />
      <br />
      <input
        type="checkbox"
        label="alternate"
        checked={projectAlternate}
        onChange={(e) => setProjectAlternate(!projectAlternate)}
      />
      Alternate
      <br />
      <Button onClick={handleSubmitData}>Salva</Button>
    </div>
  );
}
